module.exports ={
  "propertyService": "https://bzqczvkvv4.execute-api.us-east-1.amazonaws.com/dave",
  "domusoApiService": "https://st5o59r4qd.execute-api.us-east-1.amazonaws.com/dave",
  "appService": "https://domusograilsweb-dave.devmuso.com",
  "reservationService": "https://iquwh6ucv3.execute-api.us-east-1.amazonaws.com/dave",
  "userPoolId": "us-east-1_kPRU6HBdW",
  "authService": "https://zb3cnkbdif.execute-api.us-east-1.amazonaws.com/dave",
  "userPoolWebClientId": "22uckdipuae3ktona5v6ttgv9s",
  "emailLinkBase": "https://domusograilsweb-dave.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-dev@devmuso.com",
  "residentWebUI": "https://resident-dave.devmuso.com",
  "maintenanceUrl": "https://zb3cnkbdif.execute-api.us-east-1.amazonaws.com/dave/maintenance",
  "env": "dave"
}